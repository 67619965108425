import { useEffect, useMemo, useState } from 'react';

import { useI18n } from 'context/i18n';
import { getCountryLanguages, getCookie, setCookie, COOKIES, changeLocaleInUrl, eraseCookie } from 'utils/helpers';
import { getDomainValue } from 'utils/domain';
import { LabAccountIcon } from 'components/camperlab/icons/account';
import { Button } from 'components/ui/button';
import { useLocale } from 'hooks';
import { useRouter } from 'next/router';
import { Dialog, DialogHeader, DialogRoot } from 'components/ui/dialog';
import { Select, SelectItem } from 'components/ui/select';
import { AccountIconSVG } from 'components/iconsSVG/accountIcon';
import { DialogTrigger } from 'react-aria-components';
import { useClipboard } from 'hooks/use-clipboard';
import { CopySVG } from 'components/iconsSVG/copy';
import { CheckSVG } from 'components/iconsSVG/check';

type StoreDataRowProps = {
  className?: string;
};

export function StoreDataRow({ className = '' }: StoreDataRowProps) {
  const { t, storeData } = useI18n();
  const { asPath } = useRouter();
  const { country, locale } = useLocale();
  const { copy, state: copyState } = useClipboard({ reset: 2000 });
  const [employee, setEmployee] = useState(null);

  const [urlBagRedirect, setUrlBagRedirect] = useState(null);

  const isInBag = useMemo(() => asPath.startsWith(`/bolsa`), [asPath]);

  useEffect(() => {
    const cookieBag = getCookie(COOKIES.BAG);
    setEmployee(getCookie(COOKIES.CS_EMPLOYEE_ID));
    if (cookieBag) {
      setUrlBagRedirect(`${window.location.origin}/${locale}/bolsa?hash=${cookieBag}`);
    }
  }, [locale]);

  const closeSession = () => {
    eraseCookie(COOKIES.CS_EMPLOYEE_ID);
    eraseCookie(COOKIES.STORE_DATA);
    window.location.assign(`/${locale}/store_ipad`);
  };

  if (storeData?.centro || employee) {
    return (
      <div className={`flex w-full flex-row justify-between pr-2.5 lab:justify-end ${className}`}>
        {getDomainValue({
          camper: (
            <a href={`/${locale}/home_ipad`} className="hi min-w-[15%] text-center uppercase md:ml-6">
              <img src="/assets-new/img/store/ipad-logo.png" alt="Main menu" className="max-h-[3em] object-cover" />
            </a>
          ),
        })}
        <div className="flex items-center gap-2.5 sm:gap-4 md:gap-10">
          <div className="flex items-center gap-2.5">
            <div className="flex items-center gap-1">
              {getDomainValue({
                camper: <AccountIconSVG aria-hidden />,
                camperlab: <LabAccountIcon className="-mt-px mr-0.5 size-5 lab:-mt-0 lg:size-4" />,
              })}

              <span className="lab:text-sm lab:lg:text-xs">{storeData?.centro || employee}</span>
            </div>
            <div className="h-4 border-r border-dark"></div>
            {isInBag ?
              <>
                <DialogTrigger>
                  <Button variant="none" className="text-xs lab:uppercase">
                    generar URL
                  </Button>
                  <DialogRoot showOverlay>
                    <Dialog>
                      <DialogHeader label={t('bolsa', 'url.customer.service', 'URL generada')} />
                      <div className="mt-4 flex gap-4">
                        {urlBagRedirect}
                        <Button
                          variant="outline"
                          onPress={() => {
                            copy(urlBagRedirect);
                          }}
                        >
                          {copyState === 'idle' ?
                            <CopySVG className="w-6 lab:w-4" />
                          : <CheckSVG className="w-6 lab:w-4" />}
                        </Button>
                      </div>
                    </Dialog>
                  </DialogRoot>
                </DialogTrigger>
                <div className="h-4 border-r border-dark"></div>
              </>
            : null}
            {getDomainValue({
              camper: (
                <a className="animated-underline text-xs" onClick={() => closeSession()}>
                  Close session
                </a>
              ),
              camperlab: (
                <Button variant="none" className="text-sm font-bold uppercase lg:text-xs" onPress={() => closeSession()}>
                  <span className="animated-underline text-xs">Close Session</span>
                </Button>
              ),
            })}
          </div>
          {storeData && storeData?.countries ?
            <Select
              placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
              variant="empty"
              aria-label="Select a country"
              onSelectionChange={(val) => {
                const searchURL = new URLSearchParams(window.location.search);
                const langToSend = getCountryLanguages(storeData.countries, val);
                const countryPriceCookie = getCookie(COOKIES.COUNTRY_PRICE);
                const countryPriceURL = searchURL.get('country_price');

                if (countryPriceURL === null) {
                  // no tiene por url, comprueba valores cookie
                  if (countryPriceCookie !== null) {
                    // tiene por cookie, mete el valor en url y no toques nada
                    searchURL.set('country_price', countryPriceCookie);
                  } else {
                    // setea la cookie first time con market
                    searchURL.set('country_price', country);
                    setCookie(COOKIES.COUNTRY_PRICE, country, 1, COOKIES.DOMAIN);
                  }
                } else {
                  // tiene por url, pisa cookie siempre
                  setCookie(COOKIES.COUNTRY_PRICE, countryPriceURL, 1, COOKIES.DOMAIN);
                }

                searchURL.delete('country');
                searchURL.delete('lng');

                changeLocaleInUrl(`${langToSend}_${val}`);
              }}
              defaultSelectedKey={storeData?.countryToSend || country}
            >
              {storeData?.countries?.map((country) => (
                <SelectItem key={country.id} id={country.id}>
                  {country.name}
                </SelectItem>
              ))}
            </Select>
          : <Select
              placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
              variant="empty"
              aria-label="Select a country"
              isDisabled
              defaultSelectedKey={country}
            >
              <SelectItem id={country}>{country}</SelectItem>
            </Select>
          }
        </div>
      </div>
    );
  }

  return null;
}
